import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { ProductInterest } from '../entities/product-interest.entity';

class ProductInterestService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: ProductInterest) {
    return http.post('/product-interest', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: ProductInterest) {
    return http
      .put(`/product-interest/${payload.id}`, payload)
      .then(({ data }: AxiosResponse) => data);
  }

  public delete(productinterestId: string) {
    return http
      .delete(`/product-interest/${productinterestId}`)
      .then(({ data }: AxiosResponse) => data);
  }

  public getAllProductInterests(payload: any = {}) {
    return http.get('/product-interest', payload).then(({ data }: AxiosResponse) => data.data);
  }

  public getAllProductInterestsPaginate() {
    return http
      .get('/product-interest', {
        params: {
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getProductInterestById(id: string) {
    return http.get(`/product-interest/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new ProductInterestService(simplePaginationService);
