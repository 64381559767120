import store from '@/store';
import ProductInterestService from '../services/product-interest.service';

export class ProductInterest {
  public id: string = '';

  public description: string = '';

  public active: boolean | number = false;

  public has_relationship: boolean | number = false;

  public companyId: string = '';

  public companyName: string = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.companyName = data.company && data.company.id ? data.company.name : '';
    this.active = data.active === false ? 0 : 1;
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
    };
  }

  public save(): any {
    return ProductInterestService.create(this.format());
  }

  public update(): any {
    return ProductInterestService.update(this.format());
  }

  public delete(): any {
    return ProductInterestService.delete(this.id);
  }
}
