
import { Component, Vue } from 'vue-property-decorator';
import moment from '@/services/moment/moment.service';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import { Unit } from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/entities/unit.entity';
import unitsService from '@/modules/Administrative/modules/Enterprises/components/form/components/enterprise-units/units/services/units.service';

import statusService from '@/modules/Administrative/modules/Status/services/status.service';
import ClientListEntity from '../entities/client-list-filter.entity';
import { StatusEnum } from '../../form/enums/status.enum';
import clientService from '../../../services/client.service';
import enterpriseService from '../../../../Administrative/modules/Enterprises/services/enterprise.service';
import sellingPointService from '../../../../Administrative/modules/SellingPoints/services/selling-points.service';
import subStatusService from '../../../../Administrative/modules/Substatus/services/substatus.service';
import productInterestService from '../../../../Administrative/modules/ProductInterests/services/product-interest.service';
import { Company } from '../../../../Administrative/modules/Companies/entities/company.entity';
import { Enterprise } from '../../../../Administrative/modules/Enterprises/entities/enterprise.entity';
import { SellingPoint } from '../../../../Administrative/modules/SellingPoints/entities/selling-point.entity';
import { Substatus } from '../../../../Administrative/modules/Substatus/entities/substatus.entity';
import { ProductInterest } from '../../../../Administrative/modules/ProductInterests/entities/product-interest.entity';

import { Address } from '../../../../../components/address/entities/address.entity';
import addressService from '../../../../../components/address/services/address.service';

@Component({
  components: {
    DatePicker,
  },
})
export default class ClientFilter extends Vue {
  private show: any = '';

  private status: any[] = [];

  private periodType: string = '';

  private companies: Company[] = [];

  private enterprises: Enterprise[] = [];

  private sellingPoints: SellingPoint[] = [];

  private subStatuses: Substatus[] = [];

  private productInterests: ProductInterest[] = [];

  private periodTypes = {
    TODAY: 'TODAY',
    LASTDAYS: 'LASTDAYS',
    CUSTOM: 'CUSTOM',
  };

  private reserveOption: any[] = [
    {
      id: '1',
      name: 'Com Reserva',
    },
    {
      id: '0',
      name: 'Sem Reserva',
    },
  ];

  private units: Unit[] = [];

  private states: any[] = [];

  private cities: any[] = [];

  private filter = new ClientListEntity();

  private clear() {
    localStorage.removeItem('filter');
    this.filter = new ClientListEntity();
  }

  private setfilter() {
    localStorage.setItem('filter', JSON.stringify(this.filter));
    this.$emit('set-filter', new ClientListEntity(this.filter).format());
  }

  private validAverageWageValue() {
    if (Number(this.filter.average_wage_start) > 0 && Number(this.filter.average_wage_end) > 0) {
      return Number(this.filter.average_wage_start) > Number(this.filter.average_wage_end)
        ? 'O valor da renda inicial deve ser menor ou igual a renda final'
        : true;
    }

    return true;
  }

  private validFgtsValue() {
    if (Number(this.filter.fgts_start) > 0 && Number(this.filter.fgts_end) > 0) {
      return Number(this.filter.fgts_start) > Number(this.filter.fgts_end)
        ? 'O valor do FGTS inicial deve ser menor ou igual ao FGTS final'
        : true;
    }

    return true;
  }

  private async getStatus() {
    const { id } = this.$route.params;
    const status = id ? this.filter.status_id : undefined;
    if (!this.filter.company_id) return;
    this.status = await statusService.getStatusByCompanyId(this.filter.company_id);
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies.map((it: Company) => new Company(it));
    this.getSellingPoints();
    this.getSubStatuses();
    this.getEnterprises();
  }

  private async getSellingPoints() {
    if (!this.filter.company_id) return;
    const sellingPoints = await sellingPointService.getSellingPointByCompanyId(
      this.filter.company_id,
    );
    this.sellingPoints = sellingPoints.map((it: SellingPoint) => new SellingPoint(it));
  }

  private async getSubStatuses() {
    if (!this.filter.company_id && !this.filter.status_id) return;
    const subStatuses = await subStatusService.getSubstatusByStatusItem(
      this.filter.status_id,
      this.filter.company_id,
    );
    this.subStatuses = subStatuses.map((it: Substatus) => new Substatus(it));
  }

  private async getProductInterest() {
    if (!this.filter.company_id) return;
    const productInterests = await productInterestService.getAllProductInterests({
      params: {
        company_id: this.filter.company_id,
      },
    });
    this.productInterests = productInterests.map((it: ProductInterest) => new ProductInterest(it));
  }

  private async getEnterprises() {
    if (!this.filter.company_id) return;
    const enterprises = await enterpriseService.getEnterpriseByCompanyId(this.filter.company_id);
    this.enterprises = enterprises.map((it: Enterprise) => new Enterprise(it));
    this.getUnits();
  }

  private async getUnits() {
    if (!this.filter.enterprise_id) return;
    const units = await unitsService.getUnitsByEnterpriseId(this.filter.enterprise_id);
    this.units = units.map((it: Unit) => new Unit(it));
  }

  private setCompanyId(id: string) {
    this.filter.company_id = id;
    this.filter.enterprise_id = '';
    this.filter.unit_id = '';
    this.getSellingPoints();
    this.getSubStatuses();
    this.getProductInterest();
    this.getEnterprises();
    this.getStatus();
  }

  private setEnterpriseId(id: string) {
    this.filter.enterprise_id = id;
    this.filter.unit_id = '';
    this.getUnits();
  }

  private setStatus(status: StatusEnum) {
    this.filter.status_id = status;
    this.getSubStatuses();
  }

  private async getStates() {
    if (this.filter.state_id) {
      this.getCitiesByStateId(this.filter.state_id);
    }
    this.states = await addressService.getStates();
  }

  private allowedDaysStart(value: string) {
    if (this.filter.created_at_end) {
      return moment(value, 'YYYY-MM-DD').isSameOrBefore(
        moment(this.filter.created_at_end).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private allowedDaysEnd(value: string) {
    if (this.filter.created_at_start) {
      return moment(value, 'YYYY-MM-DD').isSameOrAfter(
        moment(this.filter.created_at_start).format('YYYY-MM-DD'),
      );
    }
    return moment(value, 'YYYY-MM-DD').isSameOrBefore(moment().format('YYYY-MM-DD'));
  }

  private setPeriodToday() {
    this.filter.created_at_start = moment().format('YYYY-MM-DD');
    this.filter.created_at_end = moment().format('YYYY-MM-DD');
  }

  private setPeriodLastDays() {
    this.filter.created_at_start = moment()
      .subtract(7, 'day')
      .format('YYYY-MM-DD');
    this.filter.created_at_end = moment().format('YYYY-MM-DD');
  }

  private clearPeriods() {
    this.filter.created_at_start = '';
    this.filter.created_at_end = '';
  }

  private setPeriodType(period: string) {
    this.periodType = period;

    const periodsFn = {
      [this.periodTypes.TODAY]: this.setPeriodToday,
      [this.periodTypes.LASTDAYS]: this.setPeriodLastDays,
      [this.periodTypes.CUSTOM]: this.clearPeriods,
    };

    periodsFn[this.periodType]();
  }

  private async getCitiesByStateId(stateId: string) {
    const state = stateId || this.filter.state_id;
    this.cities = await addressService.getCitiesByStateId(state);
  }

  private orderFilter = [
    {
      key: 'CLIENT_A_Z',
      value: 'A-Z',
    },
    {
      key: 'CLIENT_Z_A',
      value: 'Z-A',
    },
  ];

  private getFilterByLocalstorage() {
    const filter = localStorage.getItem('filter');
    if (filter) {
      this.filter = new ClientListEntity(JSON.parse(filter));
      this.show = 0;
      this.setfilter();
    }
  }

  created() {
    this.getFilterByLocalstorage();
    this.getStatus();
    this.getCompanies();
    this.getStates();
    this.getSellingPoints();
    this.getSubStatuses();
    this.getProductInterest();
    this.getEnterprises();
  }
}
