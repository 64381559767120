import { IPagination } from './pagination.interface';

export class Pagination implements IPagination {
  private paginate: number;

  public limit: number;

  public page: number;

  public total: number;

  public last_page: number;

  constructor(data: IPagination) {
    this.paginate = 1;
    this.limit = data.limit;
    this.page = data.page;
    this.total = data.total;
    this.last_page = data.last_page;
  }
}
