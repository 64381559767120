
import { Component, Vue, Prop } from 'vue-property-decorator';

import { IPagination } from '@/entities/pagination/pagination.interface';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import ClientService from '../../../services/client.service';
import { SnackbarInterface } from '../../../../../components/snackbar/interfaces/snackbar.interface';
import ListSkeleton from '../../../../shared/components/list-skeleton.component.vue';
import ClientList from '../client-list.component.vue';

@Component({ components: { ListSkeleton, SimplePagination } })
export default class ClientListTable extends Vue {
  @Prop({ type: Array, default: () => [] })
  private clients!: ClientList[];

  @Prop({ type: Object, default: () => ({}) })
  private pagination!: IPagination;

  @Prop({ type: Boolean, default: () => false })
  private loadingData!: boolean;

  private search: string = '';

  private headers: any = [
    { text: 'Cliente', value: 'name', width: '15%' },
    {
      text: 'Celular',
      value: 'cellphoneFormatted',
      align: 'center',
      width: '15%',
    },
    {
      text: 'Status',
      value: 'status.description',
      width: '15%',
      align: 'center',
    },
    {
      text: 'Sub Status',
      value: 'substatusesFormatted',
      width: '15%',
      align: 'center',
    },
    {
      text: 'Atualizado em',
      value: 'updatedAtFormatted',
      width: '15%',
      align: 'center',
    },
    {
      text: 'Ações',
      value: 'actions',
      width: '15%',
      align: 'center',
    },
  ];

  public async backPagination() {
    this.$emit('back-pagination');
  }

  public async nextPagination() {
    this.$emit('next-pagination');
  }

  public async goToFirst() {
    this.$emit('go-to-first');
  }

  private updatePage(page: number) {
    if (page === this.pagination.page) return;
    this.$emit('update-page', page);
  }

  private updateItemsPerPage(perPage: any) {
    if (perPage === this.pagination.limit) return;
    this.$emit('update-options', perPage);
  }

  private goToFormEdit(client: ClientList) {
    this.$emit('go-to-form-edit', client);
  }
}
