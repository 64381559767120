import { AxiosResponse } from 'axios';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import http from '@/services/http.service';
import { ClientForm } from '../components/form/entities/client-form.entity';

class ClientService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public getMainChannels(companyId: string) {
    return http
      .get('/channel', {
        params: {
          main: 1,
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getChannels(companyId: string) {
    return http
      .get('/channel', {
        params: {
          main: 0,
          company_id: companyId,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getClients(params: any) {
    return http.get('/order', { params }).then(({ data }: AxiosResponse) => data);
  }

  public getClientsPaginate(params: any) {
    return http
      .get('/order', {
        params: {
          ...params,
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getClientById(id: string) {
    return http.get(`/order/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public createClient(payload: ClientForm | any) {
    return http.post('/order/', payload).then(({ data }: AxiosResponse) => data.data);
  }

  public getCivilStates() {
    return http.get('/marital-status/').then(({ data }: AxiosResponse) => data.data);
  }

  public getOrderStatus(type: string, company_id: string) {
    return http
      .get('/order/dynamic-status', {
        params: {
          type,
          company_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getAllStatus(params: any = {}) {
    return http.get('/order/status', { params }).then(({ data }: AxiosResponse) => data.data);
  }

  public deleteOrderById(id: string) {
    return http.delete(`/order/${id}`).then(({ data }: AxiosResponse) => data.data);
  }

  public updateOrderById(payload: any) {
    return http.put(`/order/${payload.id}`, payload).then(({ data }: AxiosResponse) => data.data);
  }

  public setContract(id: string, contractFile: FormData) {
    return http
      .post(`/order/${id}/attachment/contract`, contractFile)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public setGeneralAttachment(id: string, attachment: FormData) {
    return http
      .post(`/order/${id}/attachment/general`, attachment)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public setDistract(id: string, attachment: FormData) {
    return http
      .post(`/order/${id}/attachment/distract`, attachment)
      .then(({ data }: AxiosResponse) => data.data);
  }
}
const simplePaginationService = new SimplePagination();
export default new ClientService(simplePaginationService);
