import moment from '@/services/moment/moment.service';
import clientService from '../../../services/client.service';

export class ClientList {
  public id: string = '';

  public name: string = '';

  public cellphone: string = '';

  public updated_at: string = '';

  public sub_statuses: string[] = [];

  public status: boolean = false;

  public company: string = '';

  constructor(data: any = {}) {
    this.id = data.id;
    this.updated_at = data.updated_at || '-';
    this.name = data.proponent_name || '-';
    this.cellphone = data.proponent_cellphone || '-';
    this.sub_statuses = data.sub_statuses || [];
    this.status = data.status || '-';
    this.company = data.company || '';
  }

  public get substatusesFormatted(): string {
    return this.sub_statuses ? this.sub_statuses.map((it: any) => it.description).join(', ') : '-';
  }

  public get updatedAtFormatted() {
    return moment(this.updated_at).format('DD/MM/YYYY');
  }

  public get cellphoneFormatted() {
    return this.cellphone
      ? String(this.cellphone).replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
      : '';
  }

  public async delete() {
    return clientService.deleteOrderById(this.id);
  }
}
