import { render, staticRenderFns } from "./client-list-table.component.vue?vue&type=template&id=4cb195f7&scoped=true&"
import script from "./client-list-table.component.vue?vue&type=script&lang=ts&"
export * from "./client-list-table.component.vue?vue&type=script&lang=ts&"
import style0 from "./client-list-table.component.vue?vue&type=style&index=0&id=4cb195f7&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cb195f7",
  null
  
)

export default component.exports