
import { Component, Vue } from 'vue-property-decorator';

import { Pagination } from '@/entities/pagination/pagination.entity';
import { IPagination } from '@/entities/pagination/pagination.interface';
import ClientService from '../../services/client.service';
import { SnackbarInterface } from '../../../../components/snackbar/interfaces/snackbar.interface';
import ListSkeleton from '../../../shared/components/list-skeleton.component.vue';
import ClientListTable from './components/client-list-table.component.vue';
import ClientFilter from './components/client-filter.component.vue';
import { ClientList } from './entities/client-list.entity';

@Component({
  components: {
    ListSkeleton,
    ClientFilter,
    ClientListTable,
  },
})
export default class ClientListComponent extends Vue {
  private clients: ClientList[] = [];

  private showDialogDelete: boolean = false;

  private loadingData: boolean = false;

  private filter: any = {};

  private paginationFilter: IPagination = new Pagination({
    limit: 10,
    page: 1,
    total: 10,
    last_page: 2,
  });

  private itemToDelete: ClientList = new ClientList();

  private createClient(): void {
    this.$router.push({
      name: 'client-form',
    });
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private async getClients() {
    const params = { ...this.filter };
    const clients = await ClientService.getClientsPaginate(params);

    this.clients = clients.map((item: any) => new ClientList(item));
  }

  async backPagination() {
    if (!ClientService.simplePaginationService.prevLink) return;
    const response = await ClientService.simplePaginationService.prev();
    this.clients = response.map((item: any) => new ClientList(item));
  }

  async nextPagination() {
    if (!ClientService.simplePaginationService.nextLink) return;
    const response = await ClientService.simplePaginationService.next();
    this.clients = response.map((item: any) => new ClientList(item));
  }

  async goToFirst() {
    if (!ClientService.simplePaginationService.firstLink) return;
    const response = await ClientService.simplePaginationService.goToFirst();
    this.clients = response.map((item: any) => new ClientList(item));
  }

  private async setFilter(filter: any) {
    this.filter = filter;
    this.paginationFilter.page = 1;
    this.getClients();
  }

  private async updatePage(page: any) {
    this.loadingData = true;
    this.paginationFilter.page = page;
    await this.getClients();
    this.loadingData = false;
  }

  private async updateOptions(perPage: any) {
    this.loadingData = true;
    if (perPage === -1) {
      this.paginationFilter.limit = this.paginationFilter.total;
      this.paginationFilter.page = 1;
    } else {
      this.paginationFilter.limit = perPage;
    }
    await this.getClients();
    this.loadingData = false;
  }

  private goToFormEdit(client: ClientList) {
    this.$router.push({
      name: 'client-edit',
      params: {
        id: client.id,
      },
    });
  }

  private async created() {
    this.loadingData = true;
    await this.getClients();
    this.loadingData = false;
  }
}
