import { Utils } from '@/services/utils/utils.service';
import moment from '@/services/moment/moment.service';

import store from '@/store';

export default class ClientListEntity {
  public name: string = '';

  public order_number: string = '';

  public created_by: string = '';

  public status_id: string = '';

  public state_id: string = '';

  public city_id: string = '';

  public company_id: string = '';

  public selling_point_id: string = '';

  public sub_status_id: string = '';

  public product_interest_id: string = '';

  public has_reserve: string = '';

  public enterprise_id: string = '';

  public unit_id: string = '';

  public cellphone: string = '';

  public order: string = '';

  public average_wage_start: string = '';

  public average_wage_end: string = '';

  public created_at_start: string = '';

  public created_at_end: string = '';

  public fgts_start: string = '';

  public fgts_end: string = '';

  constructor(data: any = {}) {
    this.name = data.name;
    this.order_number = data.order_number;
    this.created_by = data.created_by;
    this.cellphone = data.cellphone;
    this.company_id = data.company_id || store.getters.user.company_id;
    this.selling_point_id = data.selling_point_id;
    this.has_reserve = data.has_reserve;
    this.sub_status_id = data.sub_status_id;
    this.product_interest_id = data.product_interest_id;
    this.enterprise_id = data.enterprise_id;
    this.unit_id = data.unit_id;
    this.status_id = data.status_id;
    this.order = data.order;
    this.state_id = data.state_id;
    this.city_id = data.city_id;
    this.average_wage_start = data.average_wage_start;
    this.average_wage_end = data.average_wage_end;
    this.fgts_start = data.fgts_start;
    this.fgts_end = data.fgts_end;
    this.created_at_start = data.created_at_start;
    this.created_at_end = data.created_at_end;
  }

  public format() {
    return {
      ...this,
      cellphone: Utils.removeCharactersText(this.cellphone) || undefined,
      created_at_start: this.created_at_start
        ? moment(this.created_at_start).format('YYYY-MM-DD')
        : undefined,
      created_at_end: this.created_at_end
        ? moment(this.created_at_end).format('YYYY-MM-DD')
        : undefined,
      enterprise_id: this.enterprise_id ? this.enterprise_id : undefined,
      unit_id: this.unit_id ? this.unit_id : undefined,
    };
  }
}
